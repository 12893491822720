import React from 'react'
 
import ProductDetail from "../../component/ProductDetail";
import ProductFAQ from '../../component/ProductFAQ';
import { useLocation } from 'react-router-dom';
import { Helmet } from "react-helmet"
import { Link } from 'react-router-dom'
import Certifcates from '../../component/Certifcates';
import { BASE_URL } from '../../utils/utility';
import $ from "jquery";
import { useEffect, useState } from 'react';
import TopSection from '../../component/TopSection';
function BallisticVests() {

 const location = useLocation(); 
  const slug = location.pathname.split("/")[1];


  const [data, setData] = useState([]);
  const API = `https://www.indianarmour.com/indianarmourcms/APIs/Mainproduct/index.php?page_name=${slug}`;
  const ImageURL = 'https://www.indianarmour.com/indianarmourcms/';

 
  const fetchData = () => {
    fetch(API)
      .then((res) => res.json())
      .then((res) => {
        console.log( res)
        setData(res)
      })
  }
  useEffect(() => {
    fetchData()
  }, []);



  const [data1, setData1] = useState([]);
  const API1 = `https://www.indianarmour.com/indianarmourcms/APIs/Mainproduct/producttab.php?page_name=ballistic-vests`;
  

 
  const fetchData1 = () => {
    fetch(API1)
      .then((res) => res.json())
      .then((res) => {
        console.log( res)
        setData1(res)
      })
  }
  useEffect(() => {
    fetchData1()
  }, []);

  https://www.indianarmour.com/indianarmourcms/APIs/Mainproduct/producttab.php?page_name=ballistic-vests
    
 

  useEffect(() => {
    // var elementPosition = $('.header-tabspy').offset();
    // var bottomPosition = $(".view-all-product").offset();

    // $(window).scroll(function(){
  
    //     if($(window).scrollTop() >= elementPosition?.top - 100){
    //           $('.header-tabspy').addClass("stick1")
    //     } else {
    //         $('.header-tabspy').removeClass("stick1")
    //     }    

     
    //     if($(window).scrollTop() >= bottomPosition.top - 150){
    //       $('.header-tabspy').removeClass("stick1")
    // } 

    // });


    
    var sectionIds = $('a.list-group-item ');
  
      $(document).scroll(function(){
          sectionIds.each(function(){  
              var container = $(this).attr('href');
              var containerOffset = $(container).offset()?.top;
              var containerHeight = $(container).outerHeight();
              var containerBottom = containerOffset + containerHeight;
              var scrollPosition = $(document).scrollTop();
      
              if(scrollPosition < containerBottom - 20 && scrollPosition >= containerOffset - 20){
                  $(this).addClass('active');
              } else{
                  $(this).removeClass('active');
              }
      
      
          });
      });

  }, [])
  
  
  return ( 
    <div>
         <TopSection/>
    {/* banner */}
    <div className="verticale-slider">
      <div className="header-tabspy">
        <div className="row">
          <div id="list-example" className="list-group fised-navs">
        {/* <h1>{JSON.stringify(data1?.id)}</h1>  */}

    

          {data1.map((item, i) => (
           <>
              <a className="list-group-item list-group-item-action" href={"#scroll" + i}>{item?.tab_name}</a>
           </>
           ))}
          
            {/* <a className="list-group-item list-group-item-action" href="#scroll2">Overt Carrier</a>
            <a className="list-group-item list-group-item-action" href="#scroll3">Quick Release Vest</a>
            <a className="list-group-item list-group-item-action" href="#scroll4">TORC</a>
            <a className="list-group-item list-group-item-action" href="#scroll5">Valiant Tactical Carrier</a>
            <a className="list-group-item list-group-item-action" href="#scroll6">VIP Vest</a> */}
          </div>
        </div>
      </div>
      {/* Start Nav Section */}
      <nav id="navbar1" className="navbar1">
        <ul className="nav-menu1">
          <li>
            <a data-scroll="scroll1" href="#scroll1" className="dot1 active1">
            </a>
          </li>
          <li>
            <a data-scroll="scroll2" href="#scroll1" className="dot1">
            </a>
          </li>
          <li>
            <a data-scroll="scroll3" href="#scroll3" className="dot1">
            </a>
          </li>
          <li>
            <a data-scroll="scroll4" href="#scroll4" className="dot1">
            </a>
          </li>
          <li>
            <a data-scroll="scroll5" href="#scroll5" className="dot1">
            </a>
          </li>
          <li>
            <a data-scroll="scroll6" href="#scroll6" className="dot1">
            </a>
          </li>
        </ul>
      </nav>
      {/* End Nav Section */}
      {/* left right */}




     




      <div data-bs-spy="scroll" data-bs-target="#list-example" data-bs-smooth-scroll="true" className="scrollspy-example" tabIndex={0}>

      {data1.map((item, i) => (
       

<>
{i%2==0 ? 

<section className="vest-left-box section" id={"scroll" + i}>
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-md-4 col-sm-12 col-xs-12">
                <div className="vest-left-img vest-right-img" data-aos="fade-right" data-aos-offset={100}>
                  <img src={ImageURL + item.image1} className='img-fluid' alt={item.image_alt}/>
                
                </div>
              </div>
              <div className="col-lg-7 col-md-8 col-sm-12 col-xs-12">
                <div className="vest-box-set" data-aos="fade-left" data-aos-offset={100}>
                  <div className="vest-left-y-text">
                    <h2>{item?.tab_name}</h2>
                    <p  dangerouslySetInnerHTML={{__html: item.content}}></p>
                    <div className="input-group mt-5">
                    <Link to={item?.product_url} className="btn know-more-btn submit">View Product
                        <span className="input-group-text"><img src="/images/arrow.svg" /></span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> :



<section className="vest-left-box vest-left-box2 section" id={"scroll" + i}>
<div className="container">
  <div className="row">
    <div className="col-lg-7 col-md-8 col-sm-12 col-xs-12">
      <div className="vest-box-set-left" data-aos="fade-right" data-aos-offset={100}>
        <div className="vest-left-y-text">
        <h2>{item?.tab_name}</h2>
        <p  dangerouslySetInnerHTML={{__html: item.content}}></p>
          <div className="input-group mt-5">
          <Link to={item?.product_url} className="btn know-more-btn submit">View Product
              <span className="input-group-text"><img src="/images/arrow.svg" /></span>
            </Link>
          </div>
        </div>
      </div>
    </div>
    <div className="col-lg-5 col-md-4 col-sm-12 col-xs-12">
      <div className="vest-left-img" data-aos="fade-left" data-aos-offset={100}>
      <img src={ImageURL + item.image1} className='img-fluid' alt={item.image_alt}/>
      </div>
    </div>
  </div>
</div>
</section>


}



{/* ($i%2==0)? $class="tr_line2 grd_pad" : $class="tr_line1 grd_pad") */}



</>

))}
       
       
      
        
            </div>
        <section className="view-all-product">
          <div className="container">
            <div className="row">
              <div className="view-all-btn">
                <div className="input-group mt-5">
                  <a href={"ProductList/"+slug} className="btn know-more-btn submit">View All Products
                    <span className="input-group-text"><img src="/images/arrow.svg" /></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Premium quality Ballistic Vests by Indian Armour */}
        <section className="premium-quality">
          <div className="container">
            <div className="premium-he"><h2>Premium quality Ballistic Vests<br />by Indian Armour</h2></div>
            <div className="premium-quality-left">
              <div className="row">
                <div className="col-lg-5 col-md-5 col-sm-5 col-xs-12">
                  <div className="premium-text" data-aos="fade-right" data-aos-offset={100}>
                    <div className="premium-text-box">
                      <p>Our ballistic vests are made of premium quality hardwearing fabric and are designed to meet the specific needs of the wearer. All our personal protection armour products are rigorously and continuously tested to ensure that they meet the standards set by the <Link to="https://nij.ojp.gov/topics/articles/body-armor-performance-standards" target='_blank'>NIJ</Link> (National Institute of Justice). We are a global supplier of high-performance ballistic products and strive towards meeting the specific requirements of our customers. We are an international supplier catering to countries in the EU and Africa to even the Middle East and Americas.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-7 col-md-7 col-sm-7 col-xs-12">
                  <div className="premium-quality-img" data-aos="fade-left" data-aos-offset={100}>
                    <img src="/images/quality-1.png" />
                  </div>
                </div>
              </div>
            </div>
            <div className="premium-quality-right">
              <div className="row">
                <div className="col-lg-7 col-md-7 col-sm-7 col-xs-12">
                  <div className="premium-quality-img" data-aos="fade-right" data-aos-offset={100}>
                    <img src="/images/quality-2.png" />
                  </div>
                </div>
                <div className="col-lg-5 col-md-5 col-sm-5 col-xs-12">
                  <div className="premium-text" data-aos="fade-left" data-aos-offset={100}>
                    <div className="premium-text-box-right">
                      <p>The nature of defensive operations demands nothing short of perfection since the slightest error could turn out to be deadly. Being a world-class premium ballistic vest manufacturer, we ensure that our manufactured products are completely reliable. These vests are also available for multi-threat applications. We guarantee our <Link to="/">bullet proof clothing</Link> products provide protection against a large range of ammunition, stabs, and spikes, even in stealth and tactical applications. By providing security personnel with a broad choice of customized protective gear, we offer the defense industry a complete solution of <Link to="/ballistic-protection">body armour products.</Link></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="premium-quality-left mb-0">
              <div className="row">
                <div className="col-lg-5 col-md-5 col-sm-5 col-xs-12">
                  <div className="premium-text" data-aos="fade-right" data-aos-offset={100}>
                    <div className="premium-text-box">
                      <p>These products are perfect for use by the military, civil police forces, federal agencies, and first responders. Whether you need tactical gear, flexible body armour, outer vests, or plate carriers, we offer a wide range of options for you to choose from.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-7 col-md-7 col-sm-7 col-xs-12">
                  <div className="premium-quality-img" data-aos="fade-left" data-aos-offset={100}>
                    <img src="/images/quality-3.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Premium quality Ballistic Vests by Indian Armour */}
        {/* 3d slider */}
        {/* <section className="threed-sluder" style={{backgroundImage: 'url("/images/BG_2.png")'}}>
          <div className="container">
            <h2 className='text-center'> Our Ballistic Protection Vest Range Includes:</h2>
            <div className="row">
              <div className="slider-section">
                <div className="rev_slider">
                  <div className="rev_slide">
                    <Link to="/product/gauzy-vest">
                    <div className="test"><div className="threed-slider-contant"> <img src={BASE_URL + "/media/product/55/55_image5_file.png"} /><p>Gauzy Vest</p></div></div></Link>
                  </div>
                  <div className="rev_slide">
                  <Link to="/product/overt-carrier">
                    <div className="test"><div className="threed-slider-contant"><img src={BASE_URL + "/media/product/56/56_image5_file.png" }/><p>Overt Carrier </p></div>
                    </div></Link>
                  </div>
                  <Link to="/product/quick-release-vest">
                  <div className="rev_slide">
                    <div className="test"><div className="threed-slider-contant"><img src={BASE_URL + "/media/product/7/7_image5_file.png" }/><p>Quick Release Vest</p></div>
                    </div>
                  </div></Link>
                  <Link to="/product/torc">
                  <div className="rev_slide">
                    <div className="test"><div className="threed-slider-contant"><img src={BASE_URL + "/media/product/10/10_image5_file.png"} /><p>TORC</p></div>
                    </div>
                  </div>
                  </Link>
                  <Link to="/product/valiant-tactical-carrier">
                  <div className="rev_slide">
                    <div className="test"><div className="threed-slider-contant"><img src={BASE_URL +  "/media/product/11/11_image5_file.png"} /><p>Valiant Tactical Carrier</p></div>
                    </div>
                  </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* 3d slider */}
        <section className="defense-personnel pdp py-0">
        <div className="container">
          <div className="protection-he">
            <h2>Our Ballistic Protection Vest Range Includes:</h2>

          </div>
          <div className="defense-personnel-tab">
            <div className="row g-0" data-aos="fade-up" data-aos-duration={1500}>
              <div className="col-lg-4 col-sm-4">
                <ul className="nav nav-pills flex-column" role="tablist">
                  <li className="nav-item" role="presentation">
                    <a className="nav-link active" data-bs-toggle="pill" href="#menu1" aria-selected="true" role="tab">
                      <div className="personnel-icon">
                        <span>Army Vest</span>
                      </div>
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a className="nav-link" data-bs-toggle="pill" href="#menu2" aria-selected="false" tabIndex={-1} role="tab">
                      <div className="personnel-icon">
                        <span>Assault vest</span>
                      </div>
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a className="nav-link" data-bs-toggle="pill" href="#menu3" aria-selected="false" tabIndex={-1} role="tab">
                      <div className="personnel-icon">
                        <span>Flotation Vest</span>
                      </div>
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a className="nav-link" data-bs-toggle="pill" href="#menu4" aria-selected="false" tabIndex={-1} role="tab">
                      <div className="personnel-icon">
                        <span>Front Open Molle Vest</span>
                      </div>
                    </a>
                  </li>
                  {/* <li className="nav-item" role="presentation">
                    <a className="nav-link" data-bs-toggle="pill" href="#menu5" aria-selected="false" tabIndex={-1} role="tab">
                      <div className="personnel-icon">
                        <span>Gauzy Vest</span>
                      </div>
                    </a>
                  </li> */}
                  {/* <li className="nav-item" role="presentation">
                    <a className="nav-link" data-bs-toggle="pill" href="#menu6" aria-selected="false" tabIndex={-1} role="tab">
                      <div className="personnel-icon">
                        <span>Overt Carrier</span>
                      </div>
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a className="nav-link" data-bs-toggle="pill" href="#menu7" aria-selected="false" tabIndex={-1} role="tab">
                      <div className="personnel-icon">
                        <span>GPV</span>
                      </div>
                    </a>
                  </li> */}

                  {/* <li className="nav-item" role="presentation">
                    <a className="nav-link" data-bs-toggle="pill" href="#menu8" aria-selected="false" tabIndex={-1} role="tab">
                      <div className="personnel-icon">
                        <span>K9</span>
                      </div>
                    </a>
                  </li> */}
                  <li className="nav-item" role="presentation">
                    <a className="nav-link" data-bs-toggle="pill" href="#menu9" aria-selected="false" tabIndex={-1} role="tab">
                      <div className="personnel-icon">
                        <span>Quick Release Vest</span>
                      </div>
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a className="nav-link" data-bs-toggle="pill" href="#menu10" aria-selected="false" tabIndex={-1} role="tab">
                      <div className="personnel-icon">
                        <span>Rescuer Vest</span>
                      </div>
                    </a>
                  </li>
                  {/* <li className="nav-item" role="presentation">
                    <a className="nav-link" data-bs-toggle="pill" href="#menu11" aria-selected="false" tabIndex={-1} role="tab">
                      <div className="personnel-icon">
                        <span>Savior Vest</span>
                      </div>
                    </a>
                  </li> */}
                  {/* <li className="nav-item" role="presentation">
                    <a className="nav-link" data-bs-toggle="pill" href="#menu12" aria-selected="false" tabIndex={-1} role="tab">
                      <div className="personnel-icon">
                        <span>TORC</span>
                      </div>
                    </a>
                  </li> */}
                  {/* <li className="nav-item" role="presentation">
                    <a className="nav-link" data-bs-toggle="pill" href="#menu13" aria-selected="false" tabIndex={-1} role="tab">
                      <div className="personnel-icon">
                        <span>VIP Vest</span>
                      </div>
                    </a>
                  </li> */}
                </ul>
              </div>
              <div className="col-lg-8  col-sm-8">
                <div className="tab-content">
                  <div className="tab-pane container  active" id="menu1" role="tabpanel">
                    <div className="personnel-content">
                      <div className="row align-items-center">
                        <div className="col-lg-12">
                          <div className="personnel-img">
                            <img src="/images/army-vest-home.jpg" className="img-fluid" alt="" />
                            <div className="personnel-text">
                              <h2>Army Vest</h2>
                              <p>The Army Vest is designed as a basic Armor vest for the Police and private security personnel. 
</p>
                              <Link to="/product/military-vest"  className="know-more personnel-btn">View Product <img src="/images/arrow-w.svg" /></Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane container fade" id="menu2" role="tabpanel">
                    <div className="personnel-content">
                      <div className="row align-items-center ">
                        <div className="col-lg-12">
                          <div className="personnel-img">
                          <img src="/images/assault-vest-tab.jpg" className="img-fluid" alt="" />
                            <div className="personnel-text">
                              <h2>Assault Vest</h2>
                              <p>Our tactical equipment has been designed conforming to the latest requirements of the modern warrior. 
</p>
                              <Link to="/product/assault-vest" className="know-more personnel-btn">View Product <img src="/images/arrow-w.svg" /></Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane container fade" id="menu3" role="tabpanel">
                    <div className="personnel-content">
                      <div className="row align-items-center">
                        <div className="col-lg-12">
                          <div className="personnel-img">
                          <img src="/images/flotation-vest-tab.jpg" className="img-fluid" alt="" />
                            <div className="personnel-text">
                              <h2>Flotation Vest</h2>
                              <p>The Neptune I model offers a tactical ballistic protection solution for users working on or around the water.</p>
                               
                            <Link to="/product/ballistic-flotation-vest" className="know-more personnel-btn">View Product <img src="/images/arrow-w.svg" /></Link>
                               </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="tab-pane container fade" id="menu4" role="tabpanel">
                    <div className="personnel-content">
                      <div className="row align-items-center">
                        <div className="col-lg-12">
                          <div className="personnel-img">
                          <img src={BASE_URL + "/media/product/1/1_image1_file.png"} className="img-fluid" alt="" />
                            <div className="personnel-text">
                              <h2>Bomb Blanket</h2>
                              <p>A lightweight & portable ballistic & fragmentation-resistant blanket, which can be configured in any shape & size, & can be easily folded over or placed on top of any ballistic threat.</p>
                              <a href="product/bomb-banket" className="know-more personnel-btn">View Product <img src="/images/arrow-w.svg" /></a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="tab-pane container fade" id="menu4" role="tabpanel">
                    <div className="personnel-content">
                      <div className="row align-items-center">
                        <div className="col-lg-12">
                          <div className="personnel-img">
                          <img src="/images/front-open-molle-webbing-vest-tab.jpg" className="img-fluid" alt="" />
                            <div className="personnel-text">
                              <h2>Front Open Molle Webbing Vest</h2>
                              <p>The Protector MOLLE is designed such, that it is easy to don and quick to remove. 
</p>
                              <Link to="/product/front-open-molle-webbing-vest" className="know-more personnel-btn">View Product <img src="/images/arrow-w.svg" /></Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="tab-pane container fade" id="menu5" role="tabpanel">
                    <div className="personnel-content">
                      <div className="row align-items-center ">
                        <div className="col-lg-12">
                          <div className="personnel-img">
                          <img src="/images/quick-release-vest-tab.jpg" className="img-fluid" alt="" />
                            <div className="personnel-text">
                              <h2>Gauzy Vest</h2>
                              <p>The meticulous design of the Indian Armour Gauzy Vest ensures a remarkable showcase of both design and performance prowess. This vest boasts an ergonomic and exceptionally lightweight construction, featuring 4-way stretch rip stop fabric that enhances flexibility. It stands as an exemplary and cozy choice for extended periods of wear.</p>
                              <Link to="/product/gauzy-vest" className="know-more personnel-btn">View Product <img src="/images/arrow-w.svg" /></Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  
                  {/* <div className="tab-pane container fade" id="menu6" role="tabpanel">
                    <div className="personnel-content">
                      <div className="row align-items-center ">
                        <div className="col-lg-12">
                          <div className="personnel-img">
                          <img src={BASE_URL + "/media/product/56/56_image5_file.png"} className="img-fluid" alt="" />
                            <div className="personnel-text">
                              <h2>Overt Carrier</h2>
                              <p>Indian Armour Snug covert carrier offers maximum comfort and it is designed to be discrete in every situation. It was developed for day to day use. It includes a lining so the heat and sweat management system is managed. Due to the flexibility of this product, you can have a great variety of protections within this type of carrier.</p>
                              <Link to="/product/overt-carrier" className="know-more personnel-btn">View Product <img src="/images/arrow-w.svg" /></Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
{/* 
                  <div className="tab-pane container fade" id="menu7" role="tabpanel">
                    <div className="personnel-content">
                      <div className="row align-items-center ">
                        <div className="col-lg-12">
                          <div className="personnel-img">
                          <img src={BASE_URL + "/media/product/5/5_image3_file.png"} className="img-fluid" alt="" />
                            <div className="personnel-text">
                              <h2>GPV</h2>
                              <p>IA-General Purpose Vest is designed to provide an excellent Level IIIA protection without restricting the wearer’s movement. This vest provides front, back, shoulder & side ballistic protection as a standard vest.</p>
                              <Link to="/product/gpv" className="know-more personnel-btn">View Product <img src="/images/arrow-w.svg" /></Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  {/* <div className="tab-pane container fade" id="menu8" role="tabpanel">
                    <div className="personnel-content">
                      <div className="row align-items-center ">
                        <div className="col-lg-12">
                          <div className="personnel-img">
                          <img src={BASE_URL + "/media/product/6/6_image1_file.png"} className="img-fluid" alt="" />
                            <div className="personnel-text">
                              <h2>K9</h2>
                              <p>This design puts mission and K9 first. Less bulky thus reduces stress, while increasing “optimal workingtime” and is handler feature-rich.</p>
                              <Link to="/product/k9" className="know-more personnel-btn">View Product <img src="/images/arrow-w.svg" /></Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  
                  <div className="tab-pane container fade" id="menu9" role="tabpanel">
                    <div className="personnel-content">
                      <div className="row align-items-center ">
                        <div className="col-lg-12">
                          <div className="personnel-img">
                          <img src="/images/quick-release-vest-tab.jpg" className="img-fluid" alt="" />
                            <div className="personnel-text">
                              <h2>Quick Release Vest</h2>
                              <p>In designing the Tactical Vest, we have taken into consideration the importance in the quick removal of a vest for a soldier whenever required. </p>
                              <Link to="/product/quick-release-system" className="know-more personnel-btn">View Product <img src="/images/arrow-w.svg" /></Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="tab-pane container fade" id="menu10" role="tabpanel">
                    <div className="personnel-content">
                      <div className="row align-items-center ">
                        <div className="col-lg-12">
                          <div className="personnel-img">
                          <img src="/images/rescuer-vest-tab.jpg" className="img-fluid" alt="" />
                            <div className="personnel-text">
                              <h2>Rescuer Vest</h2>
                              <p>Half Body Bomb Suit is designed using state of art material which provide high level of protection with easy don and doff feature.</p>
                              <Link to="/product/rescuer-vest" className="know-more personnel-btn">View Product <img src="/images/arrow-w.svg" /></Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  
                  {/* <div className="tab-pane container fade" id="menu11" role="tabpanel">
                    <div className="personnel-content">
                      <div className="row align-items-center ">
                        <div className="col-lg-12">
                          <div className="personnel-img">
                          <img src={BASE_URL + "/media/product/9/9_image3_file.png"} className="img-fluid" alt="" />
                            <div className="personnel-text">
                              <h2>Savior Vest</h2>
                              <p>The Saviour vest, featured in our advanced armor range, provides the highest level of protection. Built upon a standard tactical vest, the Saviour vest comes with extra safeguards for the throat, collar, thigh, shoulder, bicep, and groin areas. </p>
                              <Link to="/product/savior-vest" className="know-more personnel-btn">View Product <img src="/images/arrow-w.svg" /></Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="tab-pane container fade" id="menu12" role="tabpanel">
                    <div className="personnel-content">
                      <div className="row align-items-center ">
                        <div className="col-lg-12">
                          <div className="personnel-img">
                          <img src={BASE_URL + "/media/product/10/10_image5_file.png"} className="img-fluid" alt="" />
                            <div className="personnel-text">
                              <h2>TORC</h2>
                              <p>The Tactical Operations Response Carrier (TORC) is a versatile plate carrier system meticulously crafted to meet the exacting requirements of modern Law Enforcement Operations & Tactical Teams. </p>
                              <Link to="/product/torc" className="know-more personnel-btn">View Product <img src="/images/arrow-w.svg" /></Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  {/* <div className="tab-pane container fade" id="menu13" role="tabpanel">
                    <div className="personnel-content">
                      <div className="row align-items-center ">
                        <div className="col-lg-12">
                          <div className="personnel-img">
                          <img src={BASE_URL + "/media/product/11/11_image5_file.png"} className="img-fluid" alt="" />
                            <div className="personnel-text">
                              <h2>Valiant Tactical Carrier</h2>
                              <p>The Indian Armour Valiant tactical carrier stands out as the lightest carrier, offering unparalleled mobility while ensuring maximum comfort and the capacity to be fully equipped. </p>
                              <Link to="/product/valiant-tactical-carrier" className="know-more personnel-btn">View Product <img src="/images/arrow-w.svg" /></Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="tab-pane container fade" id="menu14" role="tabpanel">
                    <div className="personnel-content">
                      <div className="row align-items-center ">
                        <div className="col-lg-12">
                          <div className="personnel-img">
                          <img src={BASE_URL + "/media/product/12/12_image4_file.png"} className="img-fluid" alt="" />
                            <div className="personnel-text">
                              <h2>Vip Vest</h2>
                              <p>This new bulletproof under vest is a state of art product designed for discretion and flexibility. The anatomical flex points and breathable fabric give this one piece design the optimal comfort when used in hot climate zones. A discreet protection solution for VIP protection and covert security applications.
</p>
                              <Link to="/product/vip-vest" className="know-more personnel-btn">View Product <img src="/images/arrow-w.svg" /></Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}


                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
        
        {/* What are the NIJ Levels for Ballistic Vests? */}
        <section className="nij-levels">
          <div className="container">
            <div className="nij-levels-he"><h2>What are the NIJ Levels for Ballistic Vests?</h2></div>
            <div className="row">
              <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                <div className="nij-levels-img" >
                  <img src="/images/nij-1.png" />
                </div>
              </div>
              <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                <div className="nij-levels-text" >
                  <h3>Body armour is divided into five tiers by the NIJ standard 0101.06. Each class provides enhanced bullet proofing, stopping stronger and faster projectiles before they penetrate more than 44 mm.</h3>
                  <p>At Indian Armour, we offer vests within the top NIJ levels. All of our ballistic vest solutions are categorised according to their NIJ ratings and are tested in recognised laboratories such as NTS Chesapeake, in accordance with the worldwide standard NIJ (National Institute of Justice). Our products are tested at accredited laboratories to ensure that they meet or surpass its target NIJ level.</p>
                  <p> Across various testing standards for bullet proof vests, NIJ standard 0101.06 is one of the widely accepted standards for a bulletproof vest, which means that the vest has should be tested through strict testing methods in a professional laboratory according to the NIJ requirements. This will assure that the vests are bulletproof and can defend off the threats imposed by the NIJ.</p>
                </div>
              </div>
            </div>
            <div className="row">
              {/* nij tab */}
              <div className="tab-he"> <h2>The various NIJ levels in use are as follows</h2></div>
              <div className="nij-tab" >
                <ul className="nav nav-pills" role="tablist">
                  <li className="nav-item" role="presentation">
                    <a className="nav-link  active" data-bs-toggle="pill" href="#Level1" aria-selected="false" role="tab" tabIndex={-1}>
                    LEVEL IIA
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a className="nav-link" data-bs-toggle="pill" href="#Level2" aria-selected="true" role="tab">
                    LEVEL II
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a className="nav-link" data-bs-toggle="pill" href="#Level3" aria-selected="false" tabIndex={-1} role="tab">
                    LEVEL IIIA
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a className="nav-link" data-bs-toggle="pill" href="#Level4" aria-selected="false" tabIndex={-1} role="tab">
                    LEVEL III
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a className="nav-link" data-bs-toggle="pill" href="#Level5" aria-selected="false" tabIndex={-1} role="tab">
                    LEVEL IV
                    </a>
                  </li>
                </ul>
                <div className="tab-content">
                  <div className="tab-pane container fade active show" id="Level1" role="tabpanel">
                    <div className="nij-level">
                      <img src="/images/nij-level.png" />
                      <div className="nij-level-text">
                        <div className="nij-level-center">
                          <p>This is the NIJ’s lowest available category of protection. It is generally made of soft body armour and is the lightest and most flexible armour presently offered. These soft body armours can be readily concealed beneath the clothing and are utilised in low-risk scenarios.</p>
                          <p>The NIJ Level IIA stops 9mm FMJ RN, at 1165 feet per second (ft./s) and .40 S&W FMJ, 11.8g (180 gr) at 1065 ft./s</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane container fade " id="Level2" role="tabpanel">
                    <div className="nij-level">
                      <img src="/images/nij-level.png" />
                      <div className="nij-level-text">
                        <div className="nij-level-center">
                          <p>Level II vests also are light weight, flexible, and concealable beneath clothing, but they can withstand a wider variety of bullets than Level IIA vests. In addition, they provide higher blunt force protection than IIA.</p>
                          <p> All handgun rounds up to and including.357 magnum JSP are protected by the NIJ Level II vests.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane container fade" id="Level3" role="tabpanel">
                    <div className="nij-level">
                      <img src="/images/nij-level.png" />
                      <div className="nij-level-text">
                        <div className="nij-level-center">
                          <p>The highest level of soft body armour is Level IIIA. When looking for soft body armour, Level IIIA is the most common protective level. It’s a great all-around choice for lightweight and concealable protection. The Level IIIA bulletproof vests are a little heavier than the Level IIA or II bulletproof vests, but they are still quite concealable.</p>
                          <p> The level IIIA vests are designed to protect against 9 mm bullets traveling at speeds up to 1400 ft./s and .44 magnum rounds.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane container fade" id="Level4" role="tabpanel">
                    <div className="nij-level">
                      <img src="/images/nij-level.png" />
                      <div className="nij-level-text">
                        <div className="nij-level-center">
                          <p>Level III body armour, popularly known as Rifle-defeating armour, is the first level of protection against rifle rounds. Since this armour is generally made of hard plates rather than soft plates, it is not concealable. Hard armour is also heavier than soft armour, but it provides better protection as a result of this additional weight.</p>
                          <p> Conditioned LEVEL III armour is designed to withstand six bullets from a 7.62×51 NATO round moving at a speed of up to 2780 ft / sec.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane container fade" id="Level5" role="tabpanel">
                    <div className="nij-level">
                      <img src="/images/nij-level.png" />
                      <div className="nij-level-text">
                        <div className="nij-level-center">
                          <p>The NIJ Level IV body armours are designed to offer the highest basic level of protection. It is made up of hard plates, as opposed to Level IIIA and lower-level plates.</p>
                          <p> Level IV armour meets this criterion by stopping a single shot from 7.62mm armour piercing rifle (APR) bullet rather than the six that Level III armour does, therefore it isn’t necessarily superior to Level III armour. The Level IV body armour can resist more than one shot from essentially any pistol, assault rifle or sniper rifle.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* nij tab */}
            </div>
            {/* */}
            <div className="row p20">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="kevlar-text" >
                  <h2>What is Kevlar?</h2>
                  <p>Kevlar is a type of material used in body armour that protects you from gunshots. It’s a well-known material that’s used to manufacture ballistic vests, stab-proof vests, and plate carriers. Kevlar is a crucial material used in the US military’s PASGT helmet. Its high heat resistance uniforms, body armour used by police officers, security, and police tactical units such as SWAT are just a few of its civilian applications.</p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="kevlar">
                  <img src="/images/img-1.png" />
                </div>
              </div>
            </div>
            {/* */}
            {/* */}
            <div className="row wearing-body-sec">
              <div className="wearing-body"><h2>Is wearing body armour in public considered illegal?</h2></div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="kevlar-text" >
                  <p>In most circumstances, wearing body armour in public is lawful and civilians have the right to defend themselves and their loved ones. As a result, people in most of the Europe and the United States can buy bulletproof vests and other body armour. Wearing body armour while in possession of a hazardous weapon other than a handgun during the conduct or attempted commission of any crime is, nonetheless, forbidden. It’s also unlawful in several places to wear body armour if you’ve been convicted of a felony.</p>
                  <p> However, it is the buyer’s obligation to determine if he is legally permitted to acquire and use body armour. This data is true to the best of our knowledge. THIS IS NOT LEGAL ADVICE, AND IT SHOULD NOT BE TAKEN AS SUCH. Contact a licenced attorney in your jurisdiction for legal guidance. For the most up-to-date rules in your region, contact your local government and/or police enforcement.</p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="kevlar" >
                  <img src="/images/img-2.png" />
                </div>
              </div>
            </div>
            {/* */}
            {/* Certificates */}
          

        <Certifcates />
            {/* Certificates */}
          </div>
        </section>
   
        {/* faq */}
        <section className="faq-sec">
          <div className="container">
            <div className="faq-he"><h2>Frequently asked questions</h2></div>
            <div className="row">
              <div className="faq-accordion">
                <div className="row">
                  <div className="col-lg-6" >
                    <div className="accordion" id="accordionExample">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                          Owning a ballistic vest is illegal?
                          </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample" style={{}}>
                          <div className="accordion-body">
                            <p>They are now extremely popular amongst enthusiasts, preppers and survivalists, even average people worried about active shooter situations as they can provide the most basic ballistic protection not only in life threatening situations but also for everyday use.</p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                          Who uses ballistic vest most?
                          </button>
                        </h2>
                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            <p>
                            Active military, Law enforcement officers / Police Officers, Private Security, Security Guards, VIPs, Enthusiasts</p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="heading3">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapseTwo">
                          Can civilians buy ballistic vest?
                          </button>
                        </h2>
                        <div id="collapse3" className="accordion-collapse collapse" aria-labelledby="heading3" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            <p>
                            Yes, civilians are legally able to buy body armour such as bulletproof vests. If you haven’t had a crime, you are able to purchase a ballistic vest online.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>{/* /col-lg-6 */}
                  <div className="col-lg-6">
                    <div className="accordion" id="accordionExample1">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne1-1">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne1-1" aria-expanded="true" aria-controls="collapseOne1-1">
                          Can civilians buy ballistic vest?
                          </button>
                        </h2>
                        <div id="collapseOne1-1" className="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample1">
                          <div className="accordion-body">
                            <p>
                            Level IIA vests can protect against 9mm rounds which is fired at a velocity of 373 meters per second and. 40 S&W bullets.</p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo1-2">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo1-2" aria-expanded="false" aria-controls="collapseTwo1-2">
                          Can a bulletproof vest stop an AK 47?
                          </button>
                        </h2>
                        <div id="collapseTwo1-2" className="accordion-collapse collapse" aria-labelledby="headingTwo-2" data-bs-parent="#accordionExample1">
                          <div className="accordion-body">
                            <p>
                            NIJ Level IIIA ballistic vest with Level IV hard armour panels can stop AK-47 rounds including armour penetrating.</p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo1-3">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo1-3" aria-expanded="false" aria-controls="collapseTwo1-3">
                          What is the difference between Level 3 and Level 4 body armour?
                          </button>
                        </h2>
                        <div id="collapseTwo1-3" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample1">
                          <div className="accordion-body">
                            <p>
                            Level III armour is lightweight armour, and it is easier to wear covertly, and is low-cost than Level IV. NATO military forces around the world normally wear level IV armour in the most austere environments. Level IV protect from AP bullets which are fired from military-grade weaponry and technology</p> 
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>{/* /col-lg-6 */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      
      

      
      </div>
  )
}

export default BallisticVests