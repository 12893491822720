import React from 'react'
import { Link, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
const SiteMap = () => {
    const [post, setPost] = useState([]);
    const API = 'https://www.indianarmour.com/indianarmourcms/APIs/Blog/';



    const fetchPost = () => {
        fetch(API)
            .then((res) => res.json())
            .then((res) => {
                console.log(res)
                setPost(res)
            })
    }
    useEffect(() => {
        fetchPost()
    }, [])

    const location = useLocation();
    const slug = location.pathname.split("/")[1];

    return (
        <>
            <section className="inner-banner d-flex align-items-center justify-content-center" style={{ backgroundImage: 'url("/images/bnner-sitemap.jpg")' }}>
                <div className="inner-banner-content1">
                    <div className="container">
                        <h1 className=" fw-bolder">Sitemap</h1>
                        <p>Explore the pages on this website
                            below</p>
                    </div>
                </div>
            </section>
            <div className='top-menu'>
            <div className="container  ">
                <div className="row">
                    <div className="col-lg-12">
                        <h3><strong>We recommend starting your search here, as the map provides direct links to frequently
                            requested information.</strong></h3>

                        <ul className="menu-list">
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/about-us">About Us</Link></li>
                            <li><Link to="/bespoke-solutions">Bespoke-Solutions</Link></li>
                            <li><Link to="/vertical-integration">Vertical Integration</Link></li>
                            <li><Link to="/ballistic-products-customization-bulk-ordering">Bulk Ordering  </Link></li>
                            <li><Link to="/contact-us">Contact Us  </Link></li>
                        </ul>
                    </div>
                </div>
            </div>
            </div>

            <section className="py-70  sitemap">
                <div className="site-map-heading">
                    <div className="container">
                        <h2><Link to="/ballistic-protection">Ballistic Protection</Link></h2>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <h3><Link to="/ballistic-vests"><img src="/images/ballistic-vest-icon.svg" alt="" />Ballistic Vest</Link></h3>
                            <ul>
                                <li><Link to="/product/military-vest">Army Vest</Link></li>
                                <li><Link to="/product/tactical-vest">Assault vest</Link></li>
                                <li><Link to="/product/ballistic-flotation-vest">Flotation Vest</Link></li>
                                <li><Link to="/product/front-open-molle-webbing-vest">Front Open Molle Vest</Link></li>
                                <li><Link to="/product/gauzy-vest">Gauzy Vest</Link></li>
                                <li><Link to="/product/overt-carrier">Overt Carrier</Link></li>
                                <li><Link to="/product/general-purpose-vest">GPV</Link></li>
                                <li><Link to="/product/k9">K9</Link></li>
                                <li><Link to="/product/quick-release-system">Quick Release Vest</Link></li>
                                <li><Link to="/product/rescuer-vest">Rescuer Vest</Link></li>
                                <li><Link to="/product/savior-vest">Savior Vest</Link></li>
                                <li><Link to="/product/torc">TORC</Link></li>
                                <li><Link to="/product/valiant-tactical-carrier">Valiant Tactical Carrier</Link></li>
                                <li><Link to="/product/vip-undervest">VIP Vest</Link></li>
                                <li><Link to="/product/plate-carrier-vest-slick-i">Plate Carrier Vest Slick-I</Link></li>
                            </ul>
                        </div>

                        <div className="col-lg-3">
                            <h3><Link to=""><img src="/images/ballistic-plate-icon.svg" alt="" />Ballistic Plate</Link></h3>
                            <ul>
                                <li><Link to="/product/hard-armour-panellevel-iii">Armour Panel (Level-III) V-1</Link></li>
                                <li><Link to="/product/hard-armour-panel-level-iii-2-9-kg">Armour Panel (Level-III) V-2</Link></li>
                                <li><Link to="/product/hard-armour-panel-level-iii-1-8-kg">Armour Panel (Level-III)  V-3</Link></li>
                                <li><Link to="/product/hard-armour-panel-level-iii-3-2-kg">Armour Panel (Level-III)  V-4</Link></li>
                                <li><Link to="/product/hard-armour-panel-level-iv-2-6-kg">Armour Panel (Level-IV) V-1</Link></li>
                                <li><Link to="/product/hard-armour-panel-level-iv-3-2-kg">Armour Panel (Level-IV)  V-2</Link></li>



                            </ul>
                        </div>

                        <div className="col-lg-3">
                            <h3><Link to=""><img src="/images/ballistic-helmet-icon.svg" alt="" />Ballistic Helmet</Link></h3>
                            <ul>
                                <li><Link to="/product/mich-helmet">Mich with Acc</Link></li>
                                <li><Link to="/product/pasgt-helmets">PASGT with Acc</Link></li>
                                <li><Link to="/product/special-force-helmet">SF with Acc</Link></li>
                                <li><Link to="/product/mid-cut-with-acc">Mid-Cut with Acc</Link></li>
                                <li><Link to="/product/patka">Patka</Link></li>
                                <li><Link to="/product/raps-helmet">RAPS Helmet</Link></li>


                            </ul>
                        </div>

                        <div className="col-lg-3">
                            <h3><Link to="/demining-clothing"><img src="/images/demining-clothing-icon.svg" alt="" /> Demining Clothing</Link></h3>
                            <ul>
                                <li><Link to="/product/de-mining-apron">Demining Apron</Link></li>
                                <li><Link to="/product/de-mining-vest">Demining vest</Link></li>
                                <li><Link to="/product/de-mining-suit">Demining suit</Link></li>
                            </ul>
                        </div>


                    </div>

                    <div className="row mt-4">

                        <div className="col-lg-4">
                            <h3><Link to="/product/armoured-vehicle"><img src="/images/land-protection-icon.svg" alt="" />Land Protection</Link></h3>
                        </div>

                        <div className="col-lg-4">
                            <h3><Link to="/product/air-protection"><img src="/images/air-protection-icon.svg" alt="" />Air Protection</Link></h3>
                        </div>

                        <div className="col-lg-4">
                            <h3><Link to="/product/naval-protection"><img src="/images/naval-protection-icon.svg" alt="" />Naval Protection</Link></h3>
                        </div>

                    </div>
                </div>
            </section>

            <section className="sitemap">
                <div className="site-map-heading">
                    <div className="container">
                        <h2><Link to="">Major Ballistic Protection Products</Link></h2>
                    </div>
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <h3><Link to="/product/ballistic-folding-shield"><img src="/images/ballistic-folding-shield-icon.svg" alt="" />Ballistic Folding Shield</Link></h3>
                        </div>
                        <div className="col-lg-4">
                            <h3><Link to="/product/ballistic-technical-fabric"><img src="/images/ballistic-technical-fabric-icon.svg" alt="" />Ballistic Technical Fabric</Link></h3>
                        </div>
                        <div className="col-lg-4">
                            <h3><Link to="/product/composite-ballistic-panels"><img src="/images/composite-ballistic-panels-icon.svg" alt="" />Composite Ballistic Panels</Link></h3>
                        </div>
                        <div className="col-lg-4">
                            <h3><Link to="/product/bomb-blanket"><img src="/images/bomb-blankets-icon.svg" alt="" />Bomb Blankets</Link></h3>
                        </div>
                        <div className="col-lg-4">
                            <h3><Link to="/product/ballistic-shield"><img src="/images/ballistic-shield-icon.svg" alt="" />Ballistic Shield</Link></h3>
                        </div>
                        <div className="col-lg-4">
                            <h3><Link to="/product/spall-liner"><img src="/images/spall-liners-icon.svg" alt="" />Spall liners</Link></h3>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-70  sitemap">
                <div className="site-map-heading">
                    <div className="container">
                        <h2><Link to="/product/ballistic-helmet-acc">Ballistic Helmet Accessories</Link></h2>
                    </div>
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <h3><Link to="/product/face-guard-visor"><img src="/images/face-guard-visor-icon.svg" alt="" />Face Guard & Visor</Link></h3>
                            <ul>
                                <li><Link to="/product/step-in-visor">STEP IN Visor</Link></li>
                                <li><Link to="/product/ballistic-visor-nij-level-iiia">Ballistic Visior</Link></li>
                            </ul>
                        </div>
                        <div className="col-lg-3">
                            <h3><Link to="/product/nvg-shroud"><img src="/images/nvg-shroud.svg" alt="" />NVG shroud</Link></h3>
                            <ul>
                                <li><Link to="/product/3-hole-skeleton">3 Hole skeleton</Link></li>

                            </ul>
                        </div>
                        <div className="col-lg-3">
                            <h3><Link to="/product/helmet-pads"><img src="/images/helmet-pads-icon.svg" alt="" />Helmet Pads</Link></h3>
                            <ul>
                                <li><Link to="/product/epp-pad">EPP pad</Link></li>
                                <li><Link to="/product/liner-Pad">Liner Pad</Link></li>

                            </ul>
                        </div>
                        <div className="col-lg-3">
                            <h3><Link to="/product/harness"><img src="/images/helmet-harness-icon.svg" alt="" />Helmet Harness</Link></h3>
                            <ul>
                                <li><Link to="/product/retention-system-with-rsc-dial">Retention System with RSC Dial</Link></li>
                                <li><Link to="/product/retention-system-with-worm-dial">Retention System with Worm Dial</Link></li>

                            </ul>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <h3><Link to="">Other Accessories</Link></h3>
                            <ul>
                                <li><Link to="">Side rails</Link></li>
                                <li><Link to="">Counterweight</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>


            <section className=" sitemap" >
                <div className="site-map-heading">
                    <div className="container">
                        <h2><Link to="/tactical-gears">Tactical Gears</Link></h2>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <ul className="list-col-3">
                                <li><Link to="/product/tactical-trousers">Tactical Trousers</Link></li>
                                <li><Link to="/product/first-aid-kit">First Aid Kit</Link></li>
                                <li><Link to="/product/tourniquet">Tourniquet</Link></li>
                                <li><Link to="/product/tactical-kit">Tactical Kit</Link></li>
                                <li><Link to="/product/tactical-bag-for-weapons">Tactical Bag For Weapons</Link></li>
                                <li><Link to="/product/ballistic-goggles">Ballistic Goggles</Link></li>
                                <li><Link to="/product/tactical-pads">Tactical Pads</Link></li>
                                <li><Link to="/product/military-tactical-backpack-rucksack-100-liter">Rucksack 100 Litre</Link></li>
                                <li><Link to="/product/tactical-uniform">Tactical Uniform</Link></li>
                                <li><Link to="/product/tactical-belt">Tactical Belt</Link></li>
                                <li><Link to="/product/tactical-molle-pouches">Tactical Pouches</Link></li>
                                <li><Link to="/product/military-tactical-Backpack-60-Litre">Tactical Backpack 60 Litre</Link></li>
                                <li><Link to="/product/vehicle-medical-bag">Vehicle Medical Bag</Link></li>
                                <li><Link to="/product/military-boots">Military shoes</Link></li>
                                <li><Link to="/product/modular-gun-holster">Gun Holster</Link></li>


                            </ul>
                        </div>
                    </div>
                </div>
            </section>


            <section className="py-70  sitemap">
                <div className="site-map-heading">
                    <div className="container">
                        <h2><Link to="/anti-riot-gear">Anti-Riot Gear</Link></h2>
                    </div>
                </div>

                <div className="container">
                    <div className="row">
                       
                        <div className="col-lg-4">
                            <h3><Link to="/product/riot-suit"><img src="/images/riot-suit-icon.svg" alt="" />Riot Suit</Link></h3>
                        </div>
                        <div className="col-lg-4">
                            <h3><Link to="/product/riot-helmet"><img src="/images/riot-helmet-icon.svg" alt="" />Riot Helmet</Link></h3>
                        </div>
                        <div className="col-lg-4">
                            <h3><Link to="/product/riot-shield"><img src="/images/riot-shield-icon.svg" alt="" />Riot Shield</Link></h3>
                        </div>

                    </div>
                </div>
            </section>

            {/* <section className="py-70  sitemap">
                <div className="site-map-heading">
                    <div className="container">
                        <h2><Link to="">Blog</Link></h2>
                    </div>
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <ul>
                                {
                                    post.map((item, index) => (

                                        <li key={index}><Link to={"../" + item.slug}>{item.title}</Link></li>



                                    ))

                                }
                            </ul>
                        </div>

                    </div>
                </div>
            </section> */}
            <section className="pb-5 sitemap">
           

                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                           <div className="d-flex gap-4">
                            <div ><Link to="/faq" className='text-dark text-decoration-none font-18'>FAQs</Link></div>
                            <div><Link to="/privacy-policy" className='text-dark text-decoration-none'>Privacy Policy</Link></div>
                            <div><Link to="/certification" className='text-dark text-decoration-none'>Certifications</Link></div>
                           </div>
                        </div>

                    </div>
                </div>
            </section>

        </>
    )
}

export default SiteMap